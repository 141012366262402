<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="580"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 4 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 20 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 类型 -->
          <a-form-item label="类型:">
            <div>
              {{ data.questionTypeName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 分数 -->
          <a-form-item label="分数:">
            <div>
              {{ data.questionScore }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 题目 -->
          <a-form-item
            label="题目:"
            :labelCol="{ span: 2, offset: 0 }"
            :wrapperCol="{ span: 22, offset: 0 }"
          >
            <div>
              {{ data.questionName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col
          :sm="24"
          :xs="24"
          v-for="(item, index) in questionChoiceList"
          :key="index"
        >
          <!-- 选项 -->
          <a-form-item>
            <div class="answer">{{ item.choiceNumber }}：{{ item.choiceName }}</div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 答案 -->
          <a-form-item
            label="答案:"
            :labelCol="{ span: 2, offset: 0 }"
            :wrapperCol="{ span: 22, offset: 0 }"
          >
            <div>
              {{ data.questionAnswer }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 解析 -->
          <a-form-item
            label="解析:"
            :labelCol="{ span: 2, offset: 0 }"
            :wrapperCol="{ span: 22, offset: 0 }"
          >
            <div>
              {{ data.questionExplain }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16"> </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as questionChoiceApi from '@/api/qms/questionChoice'
export default {
  name: 'index-Detail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      questionChoiceList: [],
      choiceList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.queryquestionChoice(this.data.questionId)
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.data.questionType === 0) {
        this.data.map((item) => {
          item.questionTypeName = '单选'
        })
      } else if (this.data.questionType === 1) {
        this.data.map((item) => {
          item.questionTypeName = '多选'
        })
      }
    },
    queryquestionChoice(questionId) {
      questionChoiceApi.page({ questionId }).then((res) => {
        if (res.code === 0) {
          this.questionChoiceList = res.data
          // console.log('xqy', res.data)
          this.questionChoiceList.map((item) => {
            // this.choiceList = item.choiceName
            console.log('item.choiceName', item.choiceName)
          })
        }
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.answer {
  padding-left: 5%;
}
</style>
